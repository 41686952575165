import { getLinkRecordCallio, getLinkRecordDiginext } from '../services/callInfoService'

const getLinkRecord = async (siteInfo, callId) => {
  let url = ''
  switch (siteInfo.syncServiceName) {
    case 'CallioService':
    {
      const response = await getLinkRecordCallio(callId, siteInfo?.apiKey)
      url = response.url
      break
    }
    case 'DininextService':
    {
      const response = await getLinkRecordDiginext(callId, siteInfo?.apiKey)
      url = response.data
      break
    }
    case 'Stringee':
    {
      url = `https://api.stringee.com/v1/call/play/${callId}?access_token=eyJjdHkiOiJzdHJpbmdlZS1hcGk7dj0xIiwidHlwIjoiSldUIiwiYWxnIjoiSFMyNTYifQ.eyJqdGkiOiJTSy4wLk5teEdDd2pxU1BjdDZ5RklGTTZzYnpWVDBRMGVEU0ktMTczMzQ5Nzg0MSIsImlzcyI6IlNLLjAuTm14R0N3anFTUGN0NnlGSUZNNnNielZUMFEwZURTSSIsImV4cCI6MTczNjA4OTg0MSwicmVzdF9hcGkiOnRydWV9.8Qmh-CsnpAhbMBU9-cXd_OgKMElNlDCF0FuS3j1ZgC4`
      break
    }
    default:
  }
  return url
}

export { getLinkRecord }
