import { Button, DatePicker, Form, Input, Modal, Select } from 'antd'
import React, { useState } from 'react'
import { FAILD, RECALL, callStatuses } from '../../../constants/callStatuses'
import ExtendCallInfo from '../Components/ExtendCallInfo'
import { useDispatch } from 'react-redux'
import { clearAndPushErrorMessage, clearAndPushNotifyMessage, clearAndPushSuccessMessage } from '../../../slices/errorMessageSlice'
import { updateCallInfo } from '../../../services/callStrategyService'
import { OUTBOUND } from '../../../constants/callDirection'

export default function UpdateCallInfoModal ({ isOpenModal, callInfoStrategySettings, callDuration, currentCallDirection, callInfoId, customerId, strategyId, afterUpdateCallInfoEvent, startTime, endTime }) {
  const [form] = Form.useForm()
  const { Option } = Select
  const { TextArea } = Input
  const dispatch = useDispatch()
  const [defaultCallStatus, setDefaultCallState] = useState(FAILD.value)
  const [isLoadingUpdateCallInfo, setIsLoadingUpdateCallInfo] = useState(false)

  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const employeeId = userInfo?.id

  const handleUpdateCallInfo = async (callInfo) => {
    if (!callInfoId) {
      dispatch(clearAndPushNotifyMessage('Cuộc gọi chưa khởi tạo xong, vui lòng thử lại'))
      return
    }
    setIsLoadingUpdateCallInfo(true)
    const extendObject = {}
    for (let [key, value] of Object.entries(callInfo)) {
      if (key.includes('_custom') && value) {
        const realKey = key.replace('_custom', '')
        console.log(`${realKey}: ${value}`)
        if (Array.isArray(value)) value = value.join(',')
        Object.assign(extendObject, { [realKey]: value })
      }
    }

    if (callInfo.status === RECALL.value && !callInfo.callLaterTime) {
      dispatch(clearAndPushErrorMessage('Vui lòng chọn thời gian khách hẹn gọi lại'))
      setIsLoadingUpdateCallInfo(false)
    } else {
      const callFullInfo = {
        strategyId,
        employeeId,
        extendInfo: JSON.stringify(extendObject),
        callDirection: currentCallDirection,
        startTime,
        endTime,
        ...callInfo
      }
      if (currentCallDirection === OUTBOUND.value) {
        callFullInfo.customerId = customerId
      }

      const response = await updateCallInfo(callInfoId, callFullInfo)
      if (response) {
        if (response.success) {
          dispatch(clearAndPushSuccessMessage('Cập nhật thành công'))
          form.resetFields()
        } else {
          dispatch(clearAndPushErrorMessage(response.message))
        }
      }

      setIsLoadingUpdateCallInfo(false)

      afterUpdateCallInfoEvent()
    }
  }

  const extendDuration = () => {
    let fakeDurations = 0
    const originalDuration = (endTime - startTime) / 1000
    if (originalDuration > 0 && originalDuration < 10) {
      fakeDurations = originalDuration + 3
    } else if (originalDuration >= 10 && originalDuration < 20) {
      fakeDurations = originalDuration + 5
    } else if (originalDuration >= 20) {
      fakeDurations = originalDuration + 10
    }

    return Number(fakeDurations).toFixed(2)
  }

  return (
    <Modal
      title="Thông tin cuộc gọi"
      open={isOpenModal}
      closable={false}
      cancelText={false}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      maskClosable={false}
    >
      <Form
        name="Basic"
        layout="vertical"
        form={form}
        onFinish={handleUpdateCallInfo}
        fields={[
          {
            name: ['durations'],
            value: extendDuration()
          },
          {
            name: ['status'],
            value: defaultCallStatus
          }
        ]}
      >
        <Form.Item
          label="Kết quả"
          name="status"
          rules={[
            {
              required: true,
              message: 'Đây là trường bắt buộc' // thêm rule cho type
            }
          ]}>
          <Select onChange={(value) => {
            setDefaultCallState(value)
          }}>
            {callStatuses.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {
          defaultCallStatus === RECALL.value
            ? <Form.Item label="Thời gian khách hẹn gọi lại" name="callLaterTime">
              <DatePicker showTime />
            </Form.Item>
            : <></>
        }
        <Form.Item label="Thời gian gọi" name="durations">
          <Input readOnly />
        </Form.Item>

        <ExtendCallInfo
          callInfoStrategySettings={callInfoStrategySettings}
          callStatus={defaultCallStatus}
        />

        <Form.Item label="Ghi chú" name="note">
          <TextArea />
        </Form.Item>

        <Form.Item >
          <Button loading={isLoadingUpdateCallInfo} type="primary" htmlType="submit">
            Hoàn thành
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}
