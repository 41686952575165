import React, { useEffect, useState, useRef } from 'react'
import { Card, Button } from 'antd'
import {
  updateCallStrategyFail,
  getCustomerInfo,
  getStrategySettingInfo
} from '../../../services/callStrategyService'
import { useLocation, Prompt } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { clearAndPushErrorMessage, clearAndPushSuccessMessage, clearEntryMessage, pushErrorMessage } from '../../../slices/errorMessageSlice'
import { getLinePhoneByCurrentUser } from '../../../services/linePhoneService'
import { AUTO, NORMAL } from '../../../constants/strategyType'
import StrategyScrip from './StrategyScrip'
import CustomerInfo from './CustomerInfo'
import CallHistoryModal from './CallHistoryModal'
import CallCardTitle from './CallCardTitle'
import JsSIP from 'jssip'
import { YYYYMMDD_HHMMSS } from '../../../constants/dateTimeFormat'
import moment from 'moment'
import { getCurrentUserId, getStaticInfo } from '../../../services/common'
import audioPlayer from '../../../utils/audioPlayer'
import { INCOMMING, OUTBOUND } from '../../../constants/callDirection'
import * as Sentry from '@sentry/react'
import { createCallInfo } from '../../../services/callInfoService'
import { FAILD, OTHER } from '../../../constants/callStatuses'
import UpdateCallInfoModal from './UpdateCallInfoModal'
import IncomingCallModal from './IncomingCallModal'
import StopWatch from '../../../components/Common/Stopwatch'
import sleep from '../../../utils/sleep'
import { StringeeClient, StringeeCall } from 'stringee'

import { provider } from '../../../constants/provider'
import { offset } from 'highcharts'

function CallStrategy () {
  function useQuery () {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))

  const [remoteAudio] = useState(new Audio())
  const [hasMicroPermission, setHasMicroPermission] = useState(false)

  const [strategyId, setStrategyId] = useState(useQuery().get('strategyId'))
  const [customerInfoExtend, setCustomerInfoExtend] = useState([])
  const [callStrategyInfo, setCallStrategyInfo] = useState()
  const [customerInfo, setCustomerInfo] = useState()
  const [isModalHistory, setIsModalHistory] = useState(false)
  const [resetCustomer, setResetCustomer] = useState()
  const [customerStrategySettings, setCustomerStrategySettings] = useState([])
  const [callInfoStrategySettings, setCallInfoStrategySettings] = useState([])
  const [isContinueAutoCall, setIsContinueAutoCall] = useState(false)
  const [isUserHasLinePhone, setIsUserHasLinePhone] = useState(true)
  const [isStrategyActive, setIsStrategyActive] = useState(true)
  const [isCallBtnLoading, setIsCallBtnLoading] = useState(false)
  const [isCalling, setIsCalling] = useState(false)
  const [callButtonText, setCallButtonText] = useState('Đang kết nối')
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(false)
  const [isStartStopWatch, setIsStartStopWatch] = useState(false)
  const [isResetStopWatch, setIsResetStopWatch] = useState(false)
  const [readyForCall, setReadyForCall] = useState(false)
  const [currentCallDirection, setCurrentCallDirection] = useState(0)
  const [isStartInCommingStartWatch, setIsStartInCommingStartWatch] = useState()
  const [isResetInCommingStartWatch, setIsResetInCommingStartWatch] = useState()
  const [callInfoId, setCallInfoId] = useState()
  const [openIncommingCall, setOpenIncommingCall] = useState()
  const [callDuration, setCallDuration] = useState(0)
  const [faildData, setFaildData] = useState({})
  const [triggerCallFaild, setTriggerCallFaild] = useState(false)
  const [trigglerCallEnd, setTrigglerCallEnd] = useState(false)
  const [currentCustomerId, setCurrentCustomerId] = useState()
  // const [trigglerAutoCallEnd, setTrigglerAutoCallEnd] = useState(false)
  const [callReq, setCallReq] = useState()
  const [isOpenUpdateCallInfoModal, setIsOpenUpdateCallInfoModal] = useState(false)
  const [extension, setExtension] = useState()
  const [hiddenAutoCall, setHiddenAutoCall] = useState(false)
  const [autoCalling, setAutoCalling] = useState(false)
  const isFirstTimeCallAuto = useRef(true)
  const [isAutocall, setIsAutoCall] = useState(false)
  const [linePhone, setLinePhone] = useState()
  const [sCall, setSCall] = useState()

  const client = useRef()

  const startTime = useRef()
  const endTime = useRef()
  const trigglerAutoCallEnd = useRef(false)
  const uaRef = useRef()
  const sessionRef = useRef()
  const incomingSessionRef = useRef()
  const connectionHub = useRef()
  const isReporting = useRef(false)
  const needReport = useRef(false)
  const isFirstTimeFaildRef = useRef(true)
  const isFirstTimeEndRef = useRef(true)
  const isAutoEndRef = useRef(true)

  const callIdRef = useRef()
  const isJustFaild = useRef(false)

  const stateCode = useRef(0)

  const dispatch = useDispatch()

  const handleGetCustomerInfo = async () => {
    const response = await getCustomerInfo(strategyId)
    if (response.success) {
      const customerInfo = response.customerCallInfo
      return customerInfo
    } else {
      dispatch(clearAndPushErrorMessage(response.message))
    }
  }

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(function (stream) {
        console.log('You let me use your mic!')
        setHasMicroPermission(true)
      })
      .catch((err) => {
        console.log('No mic for you!', err)
        setHasMicroPermission(false)
      })

    return () => {
      dispatch(clearEntryMessage())
    }
  }, [])

  const connectJssip = (linePhone) => {
    try {
      setExtension(linePhone?.extension)
      const siteInfo = JSON.parse(localStorage.getItem('siteInfo'))

      const socket = new JsSIP.WebSocketInterface(siteInfo?.socketServer)
      socket.via_transport = 'WSS'

      const uaConfig = {
        uri: `sip:${linePhone?.extension}@${siteInfo?.uriSuffix}`,
        password: `${linePhone?.secret}`
      }

      if (siteInfo?.uriSuffix === 'cloud.telebot.vn') {
        socket.via_transport = 'WS'
        uaConfig.contact_uri = `sip:${linePhone?.extension}@${siteInfo?.uriSuffix}`
        uaConfig.registrar_server = siteInfo?.uriSuffix
        uaConfig.authorization_user = linePhone?.extension
        uaConfig.session_timers = false
      }

      uaConfig.sockets = [socket]

      console.log('uaConfig', uaConfig)

      const ua = new JsSIP.UA(uaConfig)

      uaRef.current = ua

      uaRef.current.on('connecting', () => {
        console.log('start connecting ua', moment().format(YYYYMMDD_HHMMSS))
        setCallButtonText('Đang kết nối')
      })

      uaRef.current.on('connected', () => {
        // setReadyForCall(true)
        // setCallButtonText('Gọi')
        console.log('Ua connected', moment().format(YYYYMMDD_HHMMSS))
      })

      uaRef.current.on('registered', () => {
        console.log('Ua registered!', moment().format(YYYYMMDD_HHMMSS))
        setReadyForCall(true)
        setCallButtonText('Gọi')
      })

      uaRef.current.on('disconnected', () => {
        console.log('Ua disconnected...')

        setReadyForCall(false)
      })

      uaRef.current.on('unregistered', () => {
        console.log('Unregistered!!!')
        // const ready = uaRef.current.isConnected()
        // setReadyForCall(ready)

        setReadyForCall(false)
      })

      uaRef.current.on('registrationFailed', (data) => {
        // setReadyForCall(uaRef.current.isConnected())
        setReadyForCall(false)
        setCallButtonText('Đăng ký thất bại')
      })

      uaRef.current.on('newRTCSession', async (data) => {
        console.log('newRTCSession', data)
        const { siteId, userId } = getStaticInfo()

        if (data.originator === 'local') return

        const session = data.session

        if (sessionRef.current || incomingSessionRef.current || isReporting.current) {
          session.terminate()

          return
        }
        setCurrentCallDirection(INCOMMING.value)

        audioPlayer.play('ringing')

        console.log('session', session)

        setOpenIncommingCall(true)

        session.on('failed', (data) => {
          console.log('failed data', data)
          // const message = {
          //   email: userInfo.email,
          //   time: moment().format(YYYYMMDD_HHMMSS),
          //   data
          // }
          // Sentry.captureMessage(JSON.stringify(message))
          audioPlayer.stop('ringing')
          sessionRef.current = null
          incomingSessionRef.current = null
          setIsStartInCommingStartWatch(false)
          setIsResetInCommingStartWatch(true)
          setOpenIncommingCall(false)
        })

        session.on('ended', (e) => {
          if (needReport.current) {
            console.log('incoming ended', e)
            const durations = (sessionRef.current.end_time - sessionRef.current.start_time) / 1000
            setCallDuration(durations)
            sessionRef.current = null
            incomingSessionRef.current = null
            setIsStartInCommingStartWatch(false)
            setIsResetInCommingStartWatch(true)
            setIsOpenUpdateCallInfoModal(true)
            setOpenIncommingCall(false)
            isReporting.current = true
          }
        })

        session.on('accepted', (e) => {
          audioPlayer.stop('ringing')
          console.log('accepted', e)

          setIsStartInCommingStartWatch(true)
          sessionRef.current = session
          incomingSessionRef.current = session
        })

        session.on('peerconnection', (e) => {
          const peerconnection = e.peerconnection
          const remoteStream = new MediaStream()
          remoteAudio.volume = 1

          peerconnection.ontrack = function (e) {
            peerconnection.getReceivers().forEach(function (receiver) {
              remoteStream.addTrack(receiver.track)
            })
            remoteAudio.srcObject = remoteStream
            remoteAudio.play()
          }
        })

        session.answer()
        incomingSessionRef.current = session
        sessionRef.current = session

        const callReq = {
          EmployeeId: userId,
          siteId,
          strategyId,
          callDirection: INCOMMING.value,
          phoneNumber: session.remote_identity._uri._user
        }

        const response = await createCallInfo(callReq)
        if (response.success) {
          const { callId } = response
          setCallInfoId(callId)
          needReport.current = true
          console.log('callId', callId)
        }
      })

      if (callStrategyInfo?.strategyType === NORMAL.value) {
        uaRef.current.start()
      }
    } catch (error) {
      dispatch(clearAndPushErrorMessage('Lỗi kết nối sip' + JSON.stringify(error)))
    }
  }

  useEffect(() => {
    const handler = async () => {
      const response = await getLinePhoneByCurrentUser()
      if (!response || !response.success || !response.linePhone) {
        dispatch(clearAndPushErrorMessage('Tài khoản này chưa được phân bổ số nhà mạng, vui lòng liên hệ admin để được giúp đỡ'))

        return
      }
      setLinePhone(response.linePhone)
      if (response.linePhone.providerService === provider.CALLIO) {
        connectJssip(response.linePhone)
      } else if (response.linePhone.providerService === provider.STRINGEE) {
        const sClient = new StringeeClient()
        setCallButtonText('Đang kết nối')

        sClient.connect(response.linePhone.secret)

        sClient.on('connect', function () {
          console.log('connected')
          setCallButtonText('Gọi')
          setReadyForCall(true)
        })

        sClient.on('authen', function (res) {
          console.log('authen', res)
        })

        sClient.on('otherdeviceauthen', (data) => {
          console.log('otherdeviceauthen: ', data)
        })

        sClient.on('disconnect', function () {
          console.log('disconnected')
        })

        client.current = sClient
      }
    }

    if (callStrategyInfo) {
      handler()
    }

    return () => {
      if (uaRef.current?.isConnected()) {
        uaRef.current?.stop()
      }
      client.current?.disconnect()
    }
  }, [callStrategyInfo])

  useEffect(() => {
    const handler = async () => {
      try {
        if (isJustFaild.current) {
          await sleep(1000)
        }

        const customerInfo = await handleGetCustomerInfo()
        setIsStrategyActive(customerInfo.strategyIsActive)
        setIsUserHasLinePhone(customerInfo.hasLinePhone)
        if (customerInfo && customerInfo.id && customerInfo.phoneNumber) {
          setCustomerInfo(customerInfo)
        } else {
          setCustomerInfo()
        }
        setIsLoadingCustomer(false)
      } catch (error) {
        dispatch(clearAndPushErrorMessage(error.message))
      }
    }
    if (callStrategyInfo && callStrategyInfo.strategyType === NORMAL.value) {
      handler()
    }
  }, [resetCustomer, callStrategyInfo])

  useEffect(() => {
    const getCallStrategyInfo = async () => {
      const response = await getStrategySettingInfo(strategyId)
      if (response.success) {
        const { data } = response
        const { strategyNameDescription, customerStrategySettings, callInfoStrategySettings } = data
        setCallStrategyInfo(strategyNameDescription)
        setCustomerStrategySettings(customerStrategySettings)
        setCallInfoStrategySettings(callInfoStrategySettings)
      } else {
        dispatch(clearAndPushErrorMessage(response.errorMessage))
      }
    }

    getCallStrategyInfo()
  }, [])

  useEffect(() => {
    if (customerStrategySettings && customerStrategySettings.length && customerInfo) {
      const { extendInfo } = customerInfo
      const extendInfoObject = extendInfo ? JSON.parse(extendInfo) : ''
      setCustomerInfoExtend(extendInfoObject)
    } else {
      setCustomerInfoExtend([])
    }
  }, [customerInfo, customerStrategySettings])

  useEffect(() => {
    if (isFirstTimeCallAuto.current) {
      isFirstTimeCallAuto.current = false
    } else {
      if (isAutocall) {
        handleCallOut()
      }
    }
  }, [customerInfo?.phoneNumber])

  const handleWhenCallFaild = async (data) => {
    console.log('failed data', data)

    let status = OTHER.value
    let faildCause = data.cause
    const message = data?.message
    let statusCode = 0

    if (message) {
      statusCode = message.status_code
    }

    if (data.cause === JsSIP.C.causes.CANCELED && data.originator === 'local') { // Nhân viên tắt máy
      status = FAILD.value
      faildCause = 'BUSY'
    }
    faildCause = faildCause + ' ' + statusCode.toString()

    await updateCallStrategyFail({
      strategyId,
      userId: getCurrentUserId(),
      customerId: customerInfo?.id,
      status,
      faildCause,
      callInfoId: callIdRef.current
    })
  }

  const handleGetCallHistory = async () => {
    setIsModalHistory(true)
  }

  const handleResetCustomer = () => {
    setResetCustomer(!resetCustomer)
  }

  const eventHandlers = {
    progress: function (e) {
      setCurrentCallDirection(OUTBOUND.value)
      setIsCalling(true)
      setIsCallBtnLoading(false)
    },
    failed: function (data) {
      const handler = async () => {
        isJustFaild.current = true
        setCurrentCustomerId(customerInfo?.id)
        // setFaildData(data)
        // setTriggerCallFaild(!triggerCallFaild)

        await handleWhenCallFaild(data)

        handleResetCustomer()

        setIsCallBtnLoading(false)
        setIsStartStopWatch(false)
        setIsResetStopWatch(true)
        setIsCalling(false)
        setIsLoadingCustomer(true)
      }

      handler()
    },
    ended: function (e) {
      isJustFaild.current = false
      endTime.current = moment()

      setCurrentCustomerId(customerInfo?.id)

      setIsStartStopWatch(false)
      setIsResetStopWatch(true)

      setIsCalling(false)
      setIsCallBtnLoading(false)

      dispatch(clearAndPushSuccessMessage('Cuộc gọi kết thúc'))
      setIsOpenUpdateCallInfoModal(true)
    },
    confirmed: function (e) {
      startTime.current = moment()
    },
    accepted: function (e) {
      setIsStartStopWatch(true)
      setIsResetStopWatch(false)
    },
    peerconnection: function (e) {
      const peerconnection = e.peerconnection
      const remoteStream = new MediaStream()
      remoteAudio.volume = 1

      peerconnection.ontrack = function (e) {
        peerconnection.getReceivers().forEach(function (receiver) {
          remoteStream.addTrack(receiver.track)
        })
        remoteAudio.srcObject = remoteStream
        remoteAudio.play()
      }
    }
  }

  const handleEndCall = () => {
    if (linePhone.providerService === provider.CALLIO) {
      sessionRef.current.terminate()
    } else if (linePhone.providerService === provider.STRINGEE) {
      remoteAudio.srcObject = null
      sCall.hangup((res) => {
        console.log('res', res)
        const handle = async () => {
          if (stateCode.current === 3) { // answer
            console.log('callend')
            isJustFaild.current = false
            endTime.current = moment()

            stateCode.current = 0

            setCurrentCustomerId(customerInfo?.id)

            setIsStartStopWatch(false)
            setIsResetStopWatch(true)

            setIsCalling(false)
            setIsCallBtnLoading(false)

            dispatch(clearAndPushSuccessMessage('Cuộc gọi kết thúc'))
            setIsOpenUpdateCallInfoModal(true)
          } else {
            isJustFaild.current = true
            setCurrentCustomerId(customerInfo?.id)

            await updateCallStrategyFail({
              strategyId,
              userId: getCurrentUserId(),
              customerId: customerInfo?.id,
              status: 0,
              faildCause: 'BUSY',
              callInfoId: callIdRef.current
            })

            handleResetCustomer()

            setIsCallBtnLoading(false)
            setIsStartStopWatch(false)
            setIsResetStopWatch(true)
            setIsCalling(false)
            setIsLoadingCustomer(true)
          }
        }

        handle()
      })
    }
  }

  const handleCallOut = () => {
    const handler = async () => {
      setIsCallBtnLoading(true)
      const siteInfo = JSON.parse(localStorage.getItem('siteInfo'))
      const { siteId, userId } = getStaticInfo()

      const callReq = {
        EmployeeId: userId,
        siteId,
        strategyId,
        callDirection: OUTBOUND.value,
        customerId: customerInfo?.id
      }

      const response = await createCallInfo(callReq)
      if (!response.success) {
        handleResetCustomer()

        return
      }
      const { callId } = response
      setCallInfoId(callId)
      callIdRef.current = callId
      needReport.current = true

      if (linePhone.providerService === provider.CALLIO) {
        const RTCsession = uaRef.current.call(`sip:${customerInfo?.phoneNumber}@${siteInfo?.uriSuffix}`,
          {
            mediaConstraints: { audio: true, video: false },
            eventHandlers
          })

        RTCsession.on('connecting', (data) => {
          console.log('connecting...', data)
          sessionRef.current = RTCsession
        })
      } else if (linePhone.providerService === provider.STRINGEE) {
        const call1 = new StringeeCall(client.current, linePhone.extension, customerInfo?.phoneNumber, false)

        settingCallEvents(call1)

        call1.makeCall((res) => {
          console.log('makeCall', res)
          if (res.r === 0) {
            setIsCalling(true)
            console.log('make call success')
          }
        })

        setSCall(call1)
      }
    }

    handler()
  }
  function settingCallEvents (call1) {
    call1.on('error', (info) => {
      setIsCallBtnLoading(false)

      console.log('on error: ' + JSON.stringify(info))
    })

    call1.on('addlocalstream', (stream) => {
      console.log('on addlocalstream', stream)
    })

    call1.on('addremotestream', (stream) => {
      console.log('on addremotestream', stream)
      // reset srcObject to work around minor bugs in Chrome and Edge.
      remoteAudio.srcObject = null
      remoteAudio.volume = 1
      remoteAudio.srcObject = stream
      remoteAudio.play()
    })

    call1.on('signalingstate', (state) => {
      console.log('signalingstate', state)

      if (state.code === 1) { // trying
        setIsCallBtnLoading(false)
      }

      if (state.code === 2) { // inprogess
        setCurrentCallDirection(OUTBOUND.value)
        setIsCalling(true)
        setIsCallBtnLoading(false)
      }

      if (state.code === 3) { // call anwser
        setIsStartStopWatch(true)
        setIsResetStopWatch(false)
        startTime.current = moment()
        stateCode.current = 3
      }

      if (state.code === 6) { // call ended
        if (state.sipCode === -1) {
          console.log('callend')
          isJustFaild.current = false
          endTime.current = moment()

          setCurrentCustomerId(customerInfo?.id)

          setIsStartStopWatch(false)
          setIsResetStopWatch(true)

          setIsCalling(false)
          setIsCallBtnLoading(false)

          dispatch(clearAndPushSuccessMessage('Cuộc gọi kết thúc'))
          setIsOpenUpdateCallInfoModal(true)
        } else {
          const ehandler = async () => {
            isJustFaild.current = true
            setCurrentCustomerId(customerInfo?.id)

            await updateCallStrategyFail({
              strategyId,
              userId: getCurrentUserId(),
              customerId: customerInfo?.id,
              status: 0,
              faildCause: state.sipCode + state.sipReason,
              callInfoId: callIdRef.current
            })

            handleResetCustomer()

            setIsCallBtnLoading(false)
            setIsStartStopWatch(false)
            setIsResetStopWatch(true)
            setIsCalling(false)
            setIsLoadingCustomer(true)
          }

          ehandler()
        }
      }

      if (state.code === 5) { // busy here
        console.log('callend')
      }

      const reason = state.reason
      console.log('reason', reason)
    })

    call1.on('mediastate', (state) => {
      console.log('mediastate ', state)
    })

    call1.on('info', (info) => {
      console.log('on info', info)
    })

    call1.on('otherdevice', (data) => {
      console.log('on otherdevice:' + JSON.stringify(data))
    })
  }
  const afterUpdateCallInfoEvent = () => {
    if (currentCallDirection === OUTBOUND.value) {
      setIsCallBtnLoading(false)
      handleResetCustomer()
    }

    setIsOpenUpdateCallInfoModal(false)
    setCallInfoId()
    setIsLoadingCustomer(true)
    callIdRef.current = ''
    isReporting.current = false
    needReport.current = false
    startTime.current = moment()
    endTime.current = moment()
  }

  const handleHangupIncoming = () => {
    incomingSessionRef.current.terminate()
    setOpenIncommingCall(false)
    setIsOpenUpdateCallInfoModal(true)
    isReporting.current = true
  }

  const handleAutoCallOnClick = () => {
    setIsAutoCall(true)
    setAutoCalling(true)
    handleCallOut()
  }

  const handleStopAutoCall = () => {
    handleEndCall()
    setIsAutoCall(false)
    setAutoCalling(false)
  }

  return (
    <div>
      {
        !hasMicroPermission &&
        <h3>Cấp quyền micro cho trình duyệt trước khi thực hiện cuộc gọi</h3>
      }
      {
        !isUserHasLinePhone &&
        <h3>Bạn chưa được phân bổ tài khoản gọi</h3>
      }
      {
        !isStrategyActive &&
        <h3>Chiến dịch đã dừng hoạt động</h3>
      }
      {
        !!(isUserHasLinePhone && isStrategyActive && hasMicroPermission) &&
        <div className="grid grid-cols-7 tablet:grid-cols-12 w-full tablet:p-5">
          <StrategyScrip callStrategyInfo={callStrategyInfo} />
          <Card
            className=" tablet:ml-5 col-span-7 tablet:col-end-13 tablet:col-span-5"
            title={<CallCardTitle customerId={customerInfo?.id} extension={extension} handleGetCallHistory={handleGetCallHistory} />}
          >
            <div className="text-xs w-full text-center font-bold text-[#94a3b8] ">
              Lưu ý: Cuộc gọi kết nối thất bại sẽ được báo cáo tự động
            </div>

            <div>
              {
                callStrategyInfo?.strategyType === NORMAL.value
                  ? <div>
                    <CustomerInfo customerInfo={customerInfo} customerInfoExtend={customerInfoExtend} customerStrategySettings={customerStrategySettings} />
                    {
                      customerInfo?.id &&
                      <div>
                        <div className="w-full flex justify-center">
                          {
                            isCalling
                              ? <Button
                                type="primary"
                                onClick={() => {
                                  handleEndCall()
                                }}
                              >
                                Kết thúc cuộc gọi
                              </Button>
                              : <Button
                                type="primary"
                                loading={isCallBtnLoading}
                                onClick={() => {
                                  setHiddenAutoCall(true)
                                  handleCallOut()
                                }}
                                disabled={isLoadingCustomer || !readyForCall}
                              >
                                {callButtonText}
                              </Button>
                          }
                          {/* {
                hiddenAutoCall
                  ? <></>
                  : <div>
                    {
                      !autoCalling
                        ? <Button disabled={isLoadingCustomer || !readyForCall} onClick={handleAutoCallOnClick} danger className='ml-6'>Gọi tự động</Button>
                        : <Button onClick={handleStopAutoCall} className='ml-6'>Tắt gọi tự động</Button>
                    }
                  </div>
              } */}
                        </div>
                        <div className="text-center mt-4">
                          <StopWatch isReset={isResetStopWatch} isStart={isStartStopWatch} />
                        </div>
                      </div>
                    }
                  </div>
                  : <div className='flex justify-center mt-4'>
                    {
                      isContinueAutoCall
                        ? <Button danger type='primary' onClick={() => {
                          setIsContinueAutoCall(false)
                          uaRef.current.stop()
                        }}>Tạm dừng gọi tự động</Button>
                        : <Button disabled={!uaRef.current} type="primary" onClick={() => {
                          setIsContinueAutoCall(true)
                          uaRef.current.start()
                        }}>Bắt đầu gọi tự động</Button>
                    }
                  </div>
              }
              <hr />
            </div>
          </Card>
          {/* <CallHistoryModal customerId={customerInfo?.id} isModalHistory={isModalHistory} onCancel={() => setIsModalHistory(false)} onOk={() => setIsModalHistory(false)} /> */}

          {/* <IncomingCallModal
            isResetInCommingStartWatch={isResetInCommingStartWatch}
            isStartInCommingStartWatch={isStartInCommingStartWatch}
            openIncommingCall={openIncommingCall}
            incomingPhoneNumber={incomingSessionRef.current?.remote_identity._uri._user}
            handleHangupIncoming={handleHangupIncoming} /> */}

          {
            isOpenUpdateCallInfoModal &&
            <UpdateCallInfoModal
              afterUpdateCallInfoEvent={afterUpdateCallInfoEvent}
              // callDuration={(moment.duration(endTime.current.diff(startTime.current))).asSeconds()}
              // callInfoId={callInfoId}
              callInfoId={callIdRef.current}
              callInfoStrategySettings={callInfoStrategySettings}
              currentCallDirection={currentCallDirection}
              customerId={customerInfo?.id}
              isOpenModal={isOpenUpdateCallInfoModal}
              strategyId={strategyId}
              startTime={startTime.current}
              endTime={endTime.current}
            />
          }

        </div>
      }
      <Prompt
        when={isCalling}
        message="Cuộc gọi đang diễn ra, bạn vẫn muốn rời đi?"
      />
    </div>
  )
}

export default CallStrategy
